import Vue    from 'vue'
import App    from './App.vue'
import router from './router'
import store  from './store'

import Vuetify  from 'vuetify';
import colors   from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const vuetifyOptions = {
  theme: {
    themes: {
      light: {
        primary     : colors.purple,
        secondary   : colors.grey.darken1,
        accent      : colors.shades.black,
        error       : colors.red.accent3,
        background  : colors.grey.lighten3
      },
      dark: {
        primary     : '#FFA726',
        secondary   : '#29B6F6',
        anyColor    : '#0000',
        background  : '#f2f2f2'
      }
    }
  }
};

Vue.config.productionTip = false

import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'UA-30318868-1',
  router
})

Vue.use(require('vue-cookies'))
Vue.$cookies.config('6m')

new Vue({
  router,
  store,
  vuetify: new Vuetify(vuetifyOptions),
  render: h => h(App)
}).$mount('#app')
