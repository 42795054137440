<template>
  <div>

        <v-toolbar dense flat 
            color="grey lighten-2 "
            :class="{'ma-0 text-center justify-center': $vuetify.breakpoint.smAndDown, 'ma-5': $vuetify.breakpoint.mdAndUp}"
         >

      
                <!-- Filter section -->
                <small v-if="keywordsSelected.length > 0">Filtered by:</small>
                <div class="tag_container">
                    <div :style="'display:block;position:relative;width:'+containerSize+'px;'">
                    <v-chip
                        v-for="(keyword, index) in keywordsSelected" :key="'s'+index" 
                        close class="mx-1 my-3 primary" small
                        @click="toggleKeyword(keyword, index)"
                        @click:close="toggleKeyword(keyword, index)"
                        >{{ keyword['label'] }}
                    </v-chip>  
                    </div>
                </div>
         
                <v-spacer></v-spacer>

                <!-- View Selector -->
                <v-tooltip top>
                    <span>Grid View</span>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="view = 'grid'" :color="(view == 'grid')? 'black': 'grey'" class="mx-1" v-bind="attrs" v-on="on" >mdi-apps</v-icon> 
                    </template>
                </v-tooltip>    
                <v-tooltip top>
                    <span>List View</span>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="view = 'list'" :color="(view == 'list')? 'black': 'grey'" class="mx-1" v-bind="attrs" v-on="on">mdi-format-list-bulleted-square</v-icon>
                    </template>
                </v-tooltip>    

                <v-tooltip top>
                    <span>Timeline View</span>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="view = 'timeline'" :color="(view == 'timeline')? 'black': 'grey'" class="mx-1" v-bind="attrs" v-on="on">mdi-clock-outline</v-icon>
                    </template>
                </v-tooltip>   


        </v-toolbar>    

        <!-- Selected keywords -->
        <v-layout wrap class="justify-center mb-5 mt-5">     
            <v-lazy v-for="(keyword, index) in keywords" :key="'c'+index" >
            
                <v-card
                    class="ma-1  text-center justify-center"
                    width="150"
                    height="80"
                    @click="toggleKeyword(keyword, index)"
                >   
                 <v-img
                    :src="getSrc(keyword.image)"
                    class="white--text align-end"
                    height="80" 
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
                    >
                    <v-card-title v-text="keyword.label" ></v-card-title>
                    <!-- {{ keyword.count}} -->
                 </v-img>       
                </v-card>
            </v-lazy>        
        </v-layout>

  
        <!-- Grid View-->
        <v-layout wrap class="justify-center mb-5" v-if="view == 'grid'">          

                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="teal"
                    :size="50"
                ></v-progress-circular>


            <v-lazy v-for="(item, index) in filteredItems" :key="index">
                
            
                <v-card
                    class="ma-1"
                    width="175"
                    height="140"
                    
                    @click="openCredit(item)"
                >   

                    <v-img    
                        class="white--text align-end"
                        height="140" 
                        
                        :src="(item.youtube)? 'https://img.youtube.com/vi/'+item.youtube+'/hqdefault.jpg' : getSrc(item.image)"
                        >   
                        <v-app-bar flat color="rgba(0, 0, 0, 0)" v-if="item.youtube"  >
                            <v-btn color="white" icon>
                            <v-icon >mdi-filmstrip</v-icon>
                        </v-btn>
                        </v-app-bar>    
                    </v-img>
                </v-card>
            </v-lazy>
        </v-layout>

        <!-- List View-->
        <v-layout v-else-if="view == 'list'" wrap class="justify-center mb-5" >
            <v-lazy v-for="(item, index) in filteredItems" :key="index">
                <v-card @click="openCredit(item)" width="340" class="ma-1"> 
                    <v-list-item link class="pa-0" >
                    <v-list-item-avatar rounded size="80" class="pl-2" >
                        <v-img                 
                               
                            :src="(item.youtube)? 'https://img.youtube.com/vi/'+item.youtube+'/hqdefault.jpg' : getSrc(item.image)"
                            >   
                        </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content >
                    
                        <v-list-item-title class="text-wrap pr-2" >
                            <span v-if="item.title && item.company">
                               <strong> {{ item.title }}</strong><small>
                                <br>{{ item.company }}</small>
                            </span>
                            <span v-if="!item.title && item.company">
                                <strong>{{ item.company }}</strong>
                            </span>
                            <span v-if="item.title && !item.company">
                                <strong>{{ item.title }}</strong>
                            </span>
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-wrap  pr-2"  v-html="item.role.join(' | ')"></v-list-item-subtitle>

                    </v-list-item-content>
                </v-list-item>
                </v-card>
            </v-lazy>
        </v-layout>    

        <!-- Timeline View-->
        <v-layout v-else class="justify-center mb-5" >
            <v-timeline  
                :dense="$vuetify.breakpoint.smAndDown"
                class="ma-2 "> 

       

                <v-timeline-item
                    v-for="(item, index) in filteredItems" :key="index"
                    small
                    fill-dot
                >
        
                <template v-slot:opposite>
                    <span
                    v-if="filteredItems[index-1] && item.start_year !==  filteredItems[index-1].start_year"
                    class="headline font-weight-bold"
                    v-text="item.start_year"
                    ></span>

                    <small><br>
                        <span v-if="item.start_month">{{ item.start_month }}</span>
                        <span v-if="filteredItems[index-1] && item.start_year !== filteredItems[index-1].start_year && (item.end_year && item.start_year !== item.end_year)"> {{ item.start_year }}</span>                        
                        <span v-if="item.start_month && item.end_month"> - {{ item.end_month }}</span>
                        <span v-if="item.end_year && item.start_year !== item.end_year"> {{ item.end_year }}</span>                        
                    </small>

                 </template>

                <div v-if="$vuetify.breakpoint.smAndDown" class="pb-3">
                    <span
                    v-if="filteredItems[index-1] && item.start_year !==  filteredItems[index-1].start_year"
                    class="font-weight-bold"
                    >{{item.start_year}}<br></span>

                    <small> 
                        <span v-if="item.start_month">{{ item.start_month }}</span>
                        <span v-if="filteredItems[index-1] && item.start_year !== filteredItems[index-1].start_year && (item.end_year && item.start_year !== item.end_year)"> {{ item.start_year }}</span>                        
                        <span v-if="item.start_month && item.end_month"> - {{ item.end_month }}</span>
                        <span v-if="item.end_year && item.start_year !== item.end_year"> {{ item.end_year }}</span>                        
                    </small>

                 </div>


                <v-card @click="openCredit(item)"  > 
                    <v-list-item link class="pa-0" fluid >
                    <v-list-item-avatar rounded size="80" class="pl-2 " >
                        <v-img                 
                               
                            :src="(item.youtube)? 'https://img.youtube.com/vi/'+item.youtube+'/hqdefault.jpg' : getSrc(item.image)"
                            >   
                        </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content >
                    
                        <v-list-item-title class="text-wrap pr-2" >
                            <span v-if="item.title && item.company">
                               <strong> {{ item.title }}</strong><small>
                                <br>{{ item.company }}</small>
                            </span>
                            <span v-if="!item.title && item.company">
                                <strong>{{ item.company }}</strong>
                            </span>
                            <span v-if="item.title && !item.company">
                                <strong>{{ item.title }}</strong>
                            </span>
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-wrap  pr-2"  v-html="item.role.join(' | ')"></v-list-item-subtitle>


                    </v-list-item-content>
                </v-list-item>
                </v-card>
                </v-timeline-item>
            </v-timeline>
        </v-layout>
    

    </div>
</template>

<script>
import allCredits from '../datasets/credits.json';

export default {
    data() {
        return { 
            loading     : false,
            view        : 'list',
            credits     : allCredits,
            keywordsSelected    :[],
            keyLimit    : 5,
            showMore    : false,
            credit      : [],
            sideBar     : false
        }
    },
    computed: {
        searchTerm(){
            let searchterm = [];
            let selected = this.keywordsSelected;
            for(var i = 0; i < selected.length; i++){
                let eachword = selected[i].label.split(/\s+/);
                searchterm = searchterm.concat(eachword);
            }
            searchterm = searchterm.map(v => v.toLowerCase());
            return searchterm;
        },
        filteredItems(){
            if(this.keywordsSelected.length == 0) return this.credits;

            let searchterm = this.searchTerm;

            return this.credits.filter((item) =>{

                let tags = [];
                if(item.keywords){
                    for(var k = 0; k < item.keywords.length; k++){
                        let role = item.keywords[k].replace(/\W/g, ' ');
                        let eachword = role.split(/\s+/);
                        tags = tags.concat(eachword);
                    }
                }     

                tags = tags.map(v => v.toLowerCase());
                let checker = (arr, target) => target.every(v => arr.includes(v));

                let isFound = (checker(tags, searchterm))? true : false;
                return (isFound)? item : false;
                
            })

        },
        keywords(){

            let filtedCredits = this.filteredItems;
            var uniqueImages  = [];
            var categories    = [];

            for (var keyword = 0; keyword < filtedCredits.length; keyword++) {
                for (var w = 0; w < filtedCredits[keyword].keywords.length; w++) {   
                    let word = filtedCredits[keyword].keywords[w];
                    let cred = filtedCredits[keyword];
                    let image = (cred.youtube)? 'https://img.youtube.com/vi/'+cred.youtube+'/hqdefault.jpg' : cred.image; 

                    if(!categories.find((obj => obj.label == word))){
                        categories.push({
                            'label' : word,
                            'count' : 0
                        });
                    } 
                    let cat = categories.find((obj => obj.label == word));
                    cat.count++;
                    if(!uniqueImages.includes(image) && !cat.image){
                        uniqueImages.push(image);
                        cat.image = image;
                    }
                    if(!cat.image){ cat.image = image; }

                } 

            }
            categories.sort((a, b) => b.count - a.count);

            return categories;

        },
        containerSize(){
            return this.keywordsSelected.length * 150;
        },
        
    },
    watch: {
        searchTerm: {
            deep: true,
            handler: function () {
                this.loading = false;
            }
        }
    },
    methods: {

        openCredit(credit){
            this.$store.commit('sideBar', credit);
        },
        getSrc(img) {
            if(!img) return require('../assets/images/KOlogo.png');
            if(img.substring(0, 6) !== 'images') return img;

            try {
                return require('../assets/'+img) 
            }
            catch (e) {
                console.log('Failed to load ../assets/'+img)
                return require('../assets/images/KOlogo.png');
            }
        },
        toggleKeyword(item, index){
            this.loading = true;

            this.showMore = false;
            if(this.keywordsSelected.includes(item)){
                this.keywordsSelected.splice(index, 1);
                return;
            }
            this.keywordsSelected.push(item);
            this.$ga.event('Credit Filters', 'Selected', item.label);
        }
    },

}
</script>

<style scoped>
    .v-card__title {
        display: block;
        position: relative;
        padding: 7px ;
        margin: 0;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0rem;
        word-break: normal;
    }
    .v-card__subtitle{
        padding: 7px;
    }
    .toolbar__content{
        height: auto;
    }
    .tag_container{
        display: inline-block;
        position: relative;
        height: 100%;
        overflow: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;  
        scrollbar-width: none;  
    }
    .tag_container::-webkit-scrollbar {
        display: none;
    }
        
</style>