<template>
  <v-footer
    dark
    padless
    absolute
    class="py-5" app
  >
  <v-container fluid class="text-center">
        

        <v-btn
            v-for="social in socialMedia" :key="social.index" 
            class="white--text mx-2"
            icon :href="social.link" target="_blank"
            :title="social.title"
            >
            <img :src="getImg(social.icon)" :alt="social.title" target="_blank" class="socialIcons" >
        </v-btn>

        <v-card-text class="white--text pb-0">
        <h2>Let's collaborate!</h2>
            <br>
            <a class="white--text text-decoration-none" href="tel:+447591102130" title="Contact Number">+44 (0) 7591 102 130</a>&nbsp;&middot;&nbsp;
            <a class="white--text text-decoration-none" href="mailto:kris@krisoliver.com" title="Contact email">kris@krisoliver.com</a>
            <br><small>&copy; Kris Oliver {{ new Date().getFullYear() }} | 
                <a class="white--text text-decoration-none" href="/terms"  title="Terms &amp; Privacy">Terms &amp; Privacy</a>
            </small>
        </v-card-text>

  </v-container>
  </v-footer>
</template>

<script>
import socialLinks from '../datasets/socialLinks.json';

export default {
  name: 'Footer',
  data() {
      return {
          socialLinks   : socialLinks,
      }
  },
  methods: {
      getImg(src){
          return require('../assets'+src);
      }
    },
    computed: {
        socialMedia(){
            let links = socialLinks.filter(function(item) {
                return item.active == true;
            })
            return links;
        }
    }  
}
</script>
<style>
    .socialIcons {
        height: 35px;
        width: auto;
        vertical-align: middle;
    }
</style>