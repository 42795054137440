<template>
    <div>
 

        <v-bottom-navigation app
            v-if="activePlayer && track"
            :input-value="activePlayer"
            fixed
            grow
            height="60"
            style="z-index:20001;"
            >

            <v-snackbar
                v-model="snackbar"
                timeout="5000"
                class="mb-15"
                bottom right
                absolute
                >
                Now playing: <strong>{{ track.title }}</strong> 
            </v-snackbar>   
  
       

     

            <audio ref="audioPlayer" preload="metadata">
                <source :src="file" />
            </audio>

            <v-progress-linear
                absolute
                v-if="loading"
                indeterminate
                color="teal"
            ></v-progress-linear>
            
            <p v-else-if="!hasLoaded">Ops, error loading file</p>

            <template v-else >
                <!-- tags -->

                <template v-if="displayTimeline == true">
                    <v-btn @click="displayTimeline = false" width="30"><v-icon>mdi-arrow-left</v-icon></v-btn>
    
                    <v-slider
                        v-model="newposition"
                        :max="trackLength"
                        thumb-label
                        @mousedown="dragging = true"
                        @touchstart="dragging = true"     
                        @mouseup="jumpTo()"
                        @touchend="jumpTo()"
                        class="ma-4 pa-0"
                        
                    >
                        <template v-slot:thumb-label>
                        {{ timePassed }}
                    </template>
                    </v-slider>


                </template>
                <template v-else >
             
                    <v-btn 
                        @click="selectGenre = !selectGenre"
                    >
                        <v-badge
                            overlap
                            color="green"
                            :content="playlist.length"
                        >
                            <v-icon>mdi-playlist-music</v-icon>
                        </v-badge>
                    </v-btn>
                

                    <v-btn @click="playPrevious()"><v-icon>mdi-skip-previous</v-icon></v-btn>
                    
                    <v-btn @click="togglePlay()"><v-icon>{{ (playing)? 'mdi-pause' : 'mdi-play' }}</v-icon></v-btn>

                    <v-btn @click="playNext()"><v-icon>mdi-skip-next</v-icon></v-btn>

                    <v-btn @click="displayTimeline = true"><v-icon>mdi-compare-horizontal</v-icon></v-btn>

                </template>




            </template>
        
        
        </v-bottom-navigation>
        
        <!-- Confirm download -->
        <v-dialog
            v-model="confirm"
            width="310px"
            >
            <v-card class="text-center pa-8">
                <p class="text-left">
                    All tracks are free to download for personal use.
                    Please show your support by purchasing an album via <strong>Apple Music / Amazon</strong>.<br><br>
                    Use the contact links in footer for any other use cases.
                </p>
                
                <v-btn
                    :href="file"
                    download
                    @click="downloaded()"
                >
                    Download
                </v-btn>
            </v-card>
            
        </v-dialog>  

        <!-- Play list -->
        <v-navigation-drawer
            v-model="selectGenre"
            fixed
            
            left
            temporary
            width="280"
        >
            <v-card flat class="pb-14">
                <v-list dense>
                    <v-list-item class="py-4">
                        <v-list-item-title class="text-wrap">
                            Currently playing:<br>
                            <h2 style="line-height:110%;padding:3px 0;">{{ track.title }}</h2> 

                            <p v-if="track.details" ><br>{{ track.details }}</p>
                            <p v-if="track.vocals" ><strong>Vocals</strong><br> {{ track.vocals }}</p>
                            <p v-if="track.year" ><strong>Year</strong><br> {{ track.year }}</p>

                            <br><a @click="confirm = true" >Download track</a>
                        </v-list-item-title>
                        <v-list-item-icon><v-icon>mdi-motion-play-outline</v-icon></v-list-item-icon>
                    </v-list-item>

                    <v-divider></v-divider>
    
                    <v-list-item>
                        <v-list-item-title><strong>{{ (playlistFilters.length == 0)? 'Filter genres' : 'Selected genres' }} </strong></v-list-item-title>
                    </v-list-item>
                    <div class="mx-2 mb-3" v-if="playlistFilters.length > 0">
                    
                        <v-chip v-for="(keyword, index) in playlistFilters" :key="'m'+index"  class="mr-2 my-1"
                            close
                            @click="toggleKeyword(keyword)"
                            @click:close="toggleKeyword(keyword, index)"
                            >
                            <span>{{ keyword['label'] }}</span>     
                        </v-chip>
                    </div>
        
                    <v-list-item v-if="playlistFilters.length > 0 && availableGenres.length > 0">
                        <v-list-item-title><strong>Related Genres</strong></v-list-item-title>
                    </v-list-item>

                    <div class="mx-2 " v-if="availableGenres.length > 0">
                    
                        <v-chip v-for="(keyword, index) in availableGenres" :key="'a'+index"  class="mr-2 my-1"
                            @click="toggleKeyword(keyword)" 
                            >
                            <v-avatar  left class="white darken-4">{{ keyword['count'] }}</v-avatar>
                            <span>{{ keyword['label'] }}</span>     
                        </v-chip>
                    </div>

           
                </v-list>
                <v-list dense>
                             <v-divider class="my-3 "></v-divider>
                    <v-list-item>
                        <v-list-item-title><strong>Your playlist</strong> ({{playlist.length}})</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="(item, index) in playlist" :key="index" dense link @click="openAudio(item)" >
                        <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                    </v-list-item>

                </v-list>    
            </v-card>    
        </v-navigation-drawer> 

       </div>     

</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            activePlayer    : false,
            selectGenre     : false,
            name            : 'Unknown track',
            loading         : false,
            hasLoaded       : false,
            playing         : false,
            confirm         : false,
            file            : null,
            track           : false,
            snackbar        : false,
            trackProgress   : 1,
            trackLength     : 0,
            newposition     : 0,
            dragging        : false,
            displayTimeline : false,
        }
    },
    computed: {
        ...mapGetters(['availableGenres', 'playlist', 'playlistFilters']),
         timePassed(){
            let s = parseInt(this.newposition % 60);
            let m = parseInt((this.newposition / 60) % 60);
            return m + ':' + s ;
        }
    },    
    mounted(){
        this.$store.watch(()=>{ return this.$store.state.audioTrack  },
            (track)=>{
                this.activePlayer = true;
                this.track = track;
                this.$ga.event('Audio', 'Started', this.track.title);
            },{ deep : true }
        )
        this.$watch('track', function() {
            this.getfile(); 
        });
    },
    methods: {
        getfile(){       
            if(!this.track) return;
            const player    = this.$refs.audioPlayer;

            this.loading    = true;   
            this.hasLoaded  = false;
            this.file       = require('../assets/music/'+this.track.file);

            player.load();

            let data = this;
            player.addEventListener('progress', function () {
                var timeRanges = player.buffered;
                if (timeRanges && timeRanges.length > 0) {
                    data.playTrack();
                    data.trackLength = player.duration
                    data.hasLoaded  = true;
                    data.loading    = false;
                }
            }, data);
            
    
            player.addEventListener("ended", function(){
                player.currentTime = 0;
                data.playNext();
                data.$ga.event('Audio', 'Played', data.track.title);
            }, data);

            player.addEventListener("timeupdate", function() {
                if(!data.dragging){
                    data.newposition = player.currentTime;
                }
            }, data); 


        },
        toggleKeyword(item){
            this.$store.commit('updateFilters', item);
            this.$ga.event('Track Filters', 'Selected', item.label);
        },  
        togglePlay(){
            let audio = this.$refs.audioPlayer;
            if(audio.paused){ 
                this.playTrack()
                return
            }
            audio.pause();
            this.playing = false;
        },
        playTrack(){
            let audio = this.$refs.audioPlayer;
            audio.play();
            this.snackbar   = true;
            this.playing    = true;
        },
        playNext(){
            let playlist    = this.$store.state.playlist;
            let index       = playlist.indexOf(this.track);
            let next        = playlist[index+1];
            let nextrack    = (next)? next : playlist[0];
            this.$store.commit('audioTrack', nextrack);

        },
        playPrevious(){
            let playlist    = this.$store.state.playlist;
            let index       = playlist.indexOf(this.track);
            let next        = playlist[index-1];
            let count       = playlist.length;
            let nextrack    = (next)? next: playlist[count-1];
            this.$store.commit('audioTrack', nextrack);
        },
        closeAudioPlayer(){
            this.$store.commit('audioTrack', null);
            this.activePlayer = false;
        },
        openAudio(track){
            this.$store.commit('audioTrack', track);
        },
        jumpTo(){
            const player        = this.$refs.audioPlayer;
            if(!player) return;
            player.currentTime  = this.newposition;
            this.dragging       = false;
        },
        downloaded(){
            this.$ga.event('Audio', 'Downloaded', this.track.title);
        }
    } 
}
</script>

<style scoped>
</style>