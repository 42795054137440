<template>

    <v-layout align-content-center justify-center wrap >

        <v-card max-width="380" height="auto" class="ma-0 px-2 transparent" flat>
            
            <v-layout wrap >
                <v-card
                    width="105"
                    class="ma-2"
                    v-for="(item, index) in albums" 
                    :key="index"
                    @click="openCredit(item)"
                >   
                    <v-img    
                        height="100"
                        class="white--text align-end grey lighten-1"
                        :src="getSrc(item.image)"
                    >   
                    </v-img>              
                </v-card>
            </v-layout>


        </v-card>


        <!-- Results -->
        <v-card max-width="400" class="ma-2 pa-2 ">
            
            <v-card-title class="pa-0 px-4 ma-0">
                <v-text-field
                    v-model="search"
                    label="Search"
                    class="ma-0 pa-0 pt-2"
                    ></v-text-field>
   
            </v-card-title>

            <v-data-table
                :headers="headers"
                :items="allTracks"
                item-key="name"
                :search="search"
                :items-per-page="15"
                dense
                hide-default-header
                :footer-props="{
                    'items-per-page-text' : ''
                }"

            >
                
                <template v-slot:item="{ item }" >
                    <tr @click="openAudio(item)" style="cursor:pointer;" :ref="item.title">
                        <td style="max-width:25px;">
                            <span v-if="item.playing" >
                                <div class="playingbars"><div></div><div></div><div></div></div>
                            </span>
                            <span v-else><v-icon small>mdi-play</v-icon></span>
                        </td>
                        <td>
                            
                            {{ item.title }}
                            <small v-if="item.album"> - {{ item.album }}</small>
                        </td>
                    </tr>
                </template>

                <v-data-table
                    :footer-props="{ itemsPerPageText: 'Rows count'}"
                ></v-data-table>

            </v-data-table>

        </v-card>
        
    </v-layout>       

</template>

<script>
import allCredits from '../datasets/credits.json';
import allTracks from '../datasets/tracks.json';

import { mapGetters } from 'vuex'


export default {
    name: 'Listen',
    components: { },
    data() {
        return { 
            credits    : allCredits,
            allTracks  : allTracks,
            loading    : false,
            keywordsSelected    : [],
            keyLimit    : 10,
            showMore    : false,
            search      : '',
            headers     : [
                {
                    text    : 'Track', 
                    value   : 'title'
                },

            ]
        }
    },
    computed: {
        ...mapGetters(['availableGenres', 'playlist', 'playlistFilters']),

        albums(){
            return this.credits.filter((item) =>{
                return (item.type == "Album")? item : false;            
            })
        },
    },
    methods: {
        // ...mapActions(['toggleKeyword']),
        toggleKeyword(item){
            this.$store.commit('updateFilters', item);
        },  
        openCredit(credit){
            this.$store.commit('sideBar', credit);
        },  
        openAudio(track){
            this.$store.commit('audioTrack', track);
        },
        getSrc(img) {
            if(!img) return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
            try {
                return require('../assets/'+img) 
            }
            catch (e) {
                console.log('Failed to load ../assets/'+img)
                return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
            }
        },

       
   
    }     
}
</script>

<style>
    table.v-table tbody td {
        height: 40px;
        border: none;
    }
    .theme--light.v-table tbody tr:not(:last-child) {
        border-bottom: none;
    }
    .playingtr {
        font-weight: bold;
        background: #f2f2f2;
    }

   .playingbars {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 30px;
    }
    .playingbars div {
        display: inline-block;
        position: absolute;
        left: 2px;
        width: 5px;
        background: grey;
        animation: playingbars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
        }
    .playingbars div:nth-child(1) {
        left: 0px;
        animation-delay: -0.24s;
    }
    .playingbars div:nth-child(2) {
        left: 10px;
        animation-delay: -0.12s;
    }
    .playingbars div:nth-child(3) {
        left: 20px;
        animation-delay: 0;
    }
    @keyframes playingbars {
        0% {
            top: 10%;
            height: 90%;
        }
        50%, 100% {
            top: 60%;
            height: 20%;
        }
    }


</style>