<template>
    <v-navigation-drawer
            v-model="sideBar"
            v-if="credit"
            fixed
            right
            temporary
            width="325"
        >
            <v-card flat class="pb-14">
                <v-img 
                    contain 
                    class="white--text align-end grey lighten-1 text-center " 
                    max-height="320"
                    :src="(credit.youtube)? 'https://img.youtube.com/vi/'+credit.youtube+'/hqdefault.jpg' : getSrc(credit.image)"
                 >
                    <v-btn v-if="credit.youtube" @click="openYouTube(credit.youtube)" class="ma-4">Watch Video</v-btn>

                    <div v-if="credit.links" class="text-left pa-2 " style="background:rgb(0,0,0, 0.3);">  
                        <v-btn
                            v-for="(link, index) in credit.links" 
                            :key="index" 
                            class="mx-1"
                            icon :href="link.url" target="_blank"
                            >
                            <img :src="socialIcons(link.type)" :alt="link.type" target="_blank" class="socialIcons" >
                        </v-btn>
                    </div>

                </v-img>


                <v-card-title>
                    {{ header }}
                </v-card-title>

                <v-card-text class="text--primary text-left2" v-if="credit.details">
                    <span v-html="credit.details"></span>
                </v-card-text>

                <div >
                    
    

                    <v-simple-table v-if="credit.type == 'Album'" class="text-left">
                        <template v-slot:default>
                        <tbody>
                            <tr v-for="(track, index) in foundtracks" 
                                :key="index"
                                @click="openAudio(track)" style="cursor:pointer;"
                                >
                                <td>{{ track.track_no}}. {{ track.title }}</td>
                            </tr>
                        </tbody>

                        </template>
                    </v-simple-table>        

                     <v-simple-table v-else class="text-left">
                        <template v-slot:default>
                        <tbody>

                            <tr v-if="credit.title">
                                <td>Title</td>
                                <td>{{ credit.title }}</td>
                            </tr>

                            <tr v-if="credit.company">
                                <td>Company</td>
                                <td>{{ credit.company }}</td>
                            </tr>

                            <tr v-if="credit.location">
                                <td>Location</td>
                                <td>{{ credit.location }}</td>
                            </tr>

                            <tr v-if="credit.role">
                                <td>{{ credit.role.length > 1 ? 'Roles' : 'Role' }}</td>
                                <td>{{ credit.role.join(', ') }}</td>
                            </tr>

                            <tr v-if="credit.character">
                                <td>Character</td>
                                <td>{{ credit.character }}</td>
                            </tr>

                            <tr v-if="credit.director">
                                <td>Director</td>
                                <td>{{ credit.director }}</td>
                            </tr>

                            <tr v-if="years">
                                <td>{{ (credit.type == 'book')? 'Published' : 'Year' }} </td>
                                <td>{{ years }}</td>
                            </tr>

                            <tr v-if="credit.keywords">
                                <td>Keywords</td>
                                <td>
                                    <v-chip v-for="keyword in credit.keywords" :key="keyword" small class="ma-1">{{ keyword}}</v-chip>
                                </td>
                            </tr>

                        </tbody>
                        </template>


                    </v-simple-table>

   

    

                </div>

            </v-card>
            <!-- <v-btn @click="sideBar = false" absolute bottom right
                color="primary" >
                <v-icon>mdi-close</v-icon>
            </v-btn> -->
        </v-navigation-drawer>    

</template>

<script>
import { mapActions }   from 'vuex'
import allTracks        from '../datasets/tracks.json';

export default {
    data() {
        return { 
            credit  : false,
            tracks  : allTracks,
        }
    },
    mounted(){
        this.$store.watch(()=>{ return this.$store.state.sideBar  },
            (sideBar)=>{
                this.credit = sideBar;
                let title = this.header;
                this.$ga.event('Details', 'Opened', title);
            },{ deep : true }
        )
    },
    computed:{
        header(){
            if(this.credit.title && this.credit.company)    return this.credit.title;
            if(!this.credit.title && this.credit.company)   return this.credit.company;
            if(this.credit.title && !this.credit.company)   return this.credit.title;
            return 'Unknown';

        },
        years(){
            let display = '';
            if(this.credit.start_month) display += this.credit.start_month+' ';
            if(this.credit.end_month && !this.credit.end_year) display += ' - '+this.credit.end_month;
            if(this.credit.start_year)  display += this.credit.start_year;

            if(!this.credit.end_month && this.credit.end_year) display += ' - ';

            if(this.credit.end_month && this.credit.end_year) display += ' - '+this.credit.end_month;
            if(this.credit.end_year) display += ' '+this.credit.end_year;
            return display;

        },
        sideBar: {
            get: function(){
                return this.$store.state.sideBarActive;
            },
            set: function(sideBar){
                this.$store.commit('sideBarActive', sideBar);
            }
        },
        foundtracks(){
             return this.tracks.filter((item) =>{
                let isFound = (item.album == this.credit.title)? true : false;
                return (isFound)? item : false;                
            })
        }
    },
    methods: {
        ...mapActions(['toggleYoutubePlayer']),
        openYouTube(video){
            this.$store.commit('youtubeVideo', video);
            this.toggleYoutubePlayer(video);
            this.$ga.event('Video', 'Watched', this.header);
        },
        openAudio(track){
            this.$store.commit('audioTrack', track);
        },
        socialIcons(type){
            let icon = null;
            switch(type){
                case 'Apple':       icon = 'apple.png'; break;
                case 'Amazon':      icon = 'amazon.png'; break;
                case 'Instagram':   icon = 'instagram.png'; break;
                case 'Spotify':     icon = 'spotify.png'; break;
                case 'Youtube':     icon = 'youtube.png'; break;
                default:            icon = 'website.png';  break;
            }
            return this.getSrc('socialicons/'+icon);
        },
        getSrc(img) {
            if(!img) return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
            try {
                return require('../assets/'+img) 
            }
            catch (e) {
                console.log('Failed to load ../assets/'+img)
                return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
            }
        },
    }    
}
</script>

<style scoped >
    .v-card__title {
        text-align: center;
        word-break: normal;
    }
    .socialIcons {
        height: 35px;
        width: auto;
        vertical-align: middle;
    }
</style>