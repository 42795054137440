import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Watch',
    menu: true,
    icon: 'mdi-account-details-outline',
    component: require('../views/Watch.vue').default, 
    meta:{ 
      title: 'Kris Oliver | Watch',
      metaTags: [
        {
          name: 'description',
          content: 'Kristofer Oliver is a multidisciplinary creator specialising in delivering original content, technical/artistic consultation and standalone commission-based projects.'
        },
        {
          property: 'og:description',
          content: 'Kristofer Oliver is a multidisciplinary creator specialising in delivering original content, technical/artistic consultation and standalone commission-based projects.'
        }
      ]
    }
  },
  {
    path: '/read',
    name: 'Read',
    menu: true,
    icon: 'mdi-account-details-outline',
    component: require('../views/Read.vue').default, 
    meta:{ 
      title: 'Kris Oliver | Read',
      metaTags: [
        {
          name: 'description',
          content: 'Books written by Kristofer Oliver.'
        },
        {
          property: 'og:description',
          content: 'Books written by Kristofer Oliver.'
        }
      ]
    }
  },
  {
    path: '/listen',
    name: 'Listen',
    menu: true,
    icon: 'mdi-account-details-outline',
    component: require('../views/Listen.vue').default, 
    meta:{ 
      title: 'Kris Oliver | Listen',
      metaTags: [
        {
          name: 'description',
          content: 'Listen to tracks composed by Kris Oliver.'
        },
        {
          property: 'og:description',
          content: 'Listen to tracks composed by Kris Oliver.'
        }
      ]
    }
  },

  {
    path: '/explore',
    name: 'Explore',
    menu: true,
    icon: 'mdi-account-details-outline',
    component: require('../views/Explore.vue').default, 
    meta:{ 
      title: 'Kris Oliver | Explore',
      metaTags: [
        {
          name: 'description',
          content: 'Explore credits and past work experience.'
        },
        {
          property: 'og:description',
          content: 'Explore credits and past work experience.'
        }
      ]
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    menu: false,
    icon: 'mdi-account-details-outline',
    component: require('../views/Terms.vue').default, 
    meta:{ 
      title: 'Kris Oliver | Terms & Privacy',
      metaTags: [
        {
          name: 'description',
          content: 'The terms, privacy and legal bits.'
        },
        {
          property: 'og:description',
          content: 'The terms, privacy and legal bits.'
        }
      ]
    }
  },

  { path: '/:pathMatch(.*)*', component: require('../views/PathNotFound').default },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Map meta Data
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })

  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router
