<template>
    <v-layout align-content-center justify-center wrap class="px-5">
          <v-card
                flat
              class="ma-5" 
              v-for="(item, index) in books" 
              :key="index"
              @click="openCredit(item)"
          >   
              <v-img    
                  class="white--text align-end grey lighten-1"
                  max-height="550"
                  contain
                  :src="getSrc(item.image)"
                  >   
                  </v-img>              
          </v-card>
        </v-layout>
</template>

<script>
import allCredits from '../datasets/credits.json';

export default {
  name: 'About',
  data() {
      return { 
          credits   : allCredits,
      }
  },
  computed: {
    books(){
        return this.credits.filter((item) =>{
          return (item.type == "book")? item : false;            
        })
    }
  },
  methods: {
        openCredit(credit){
            this.$store.commit('sideBar', credit);
        },
        getSrc(img) {
            if(!img) return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
            try {
                return require('../assets/'+img) 
            }
            catch (e) {
                console.log('Failed to load ../assets/'+img)
                return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
            }
        },
  },
}
</script>

<style scoped>
 
</style>