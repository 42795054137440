<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}" >

    <nav-bar />

    <v-main>
      <cookie-optin />
      <router-view />
    </v-main>

    <audio-player />
    <youtube-player />
    <side-bar />
    <footer-section />
  </v-app>
</template>

<script>
import NavBar         from './components/NavBar.vue';
import YoutubePlayer  from './components/YoutubePlayer.vue';
import AudioPlayer    from './components/AudioPlayer.vue';
import FooterSection  from './components/Footer.vue';
import SideBar        from './components/SideBar.vue';
import CookieOptin    from './components/CookieOptin.vue';


export default {
  name: 'App',
  components: {
    NavBar,
    FooterSection,
    YoutubePlayer,
    AudioPlayer,
    SideBar,
    CookieOptin
  },
  data() {
      return { 
      }
  },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  }
};
</script>
