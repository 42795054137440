<template>
    <v-dialog 
        v-if="playerOpen"
        v-model="playerOpen" @input="closePlayer()"
        transition="dialog-bottom-transition"
        max-width="1300"
        >
            <div class="youtube_frame">
            <iframe :src="video_src" @load="load"  allowfullscreen></iframe>
            </div>
        
        <v-card v-if="!hasLoaded" class="pa-10" >
            <v-progress-linear                  
                color="primary"
                indeterminate
            ></v-progress-linear>
        </v-card>


    </v-dialog>
</template>

<script>
export default {
    data () {
        return {
            hasLoaded      : false,
            playerOpen  : false,
            src: {
                rel             : 0,
                modestbranding  : 1,
                autoplay        : 1,
                showinfo        : 0,
                autohide        : 1,
                fs              : 1,
                color           : 'white',
                theme           : 'light',
                vq              : 'hd720',
                iv_load_policy  : 3
            },
        }
    },
    created(){
        this.$store.watch(()=>{ return this.$store.state.youtubePlayer  },
            (isActive)=>{
                if(isActive){
                    this.hasLoaded  = false;  
                    this.playerOpen = true;
                } 
            },{ deep : true }
        )
    },
    computed:{
        video() { return this.$store.state.youtubeVideo },
        video_src(){
            if(!this.video) return null;

            let out = [];
            let settings = this.src;
            for (let key in settings) {
                out.push(key + '=' + encodeURIComponent(settings[key]));
            }
            return 'https://youtube.com/embed/'+ this.video +'?'+ out.join('&');
        },
    },
    methods: {
		load: function(){ 
            this.hasLoaded  = true; 
        },
        closePlayer(){
            this.$store.commit('youtubePlayer', false);
        }
  }   

}
</script>

<style scoped>
    .youtube_frame{
        position: relative;
        padding-bottom: 53%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        border:0;
    }
</style>