<template>
    <v-app-bar fixed dense flat app>
        <v-tabs>
            <v-tab v-for="route in pages" :key="route.index" :to="route.path">
                {{route.name}}
            </v-tab>
        </v-tabs>     
    </v-app-bar>
</template>

<script>

export default {
    name: 'NavBar',
    data() {
        return {
            routes        : this.$router.options.routes,
            drawer        : false,
        }
    },
    computed: {
        pages(){
            let pages = this.routes.filter(function(item) {
                return item.menu == true;
            })
            return pages;
        }
    }
}

</script>

<style>
    .v-slide-group__prev {
        display: none !important;
    }

</style>