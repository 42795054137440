<template>
    <v-layout align-content-center justify-center wrap class="px-5 my-5">
          <v-card max-width="950" class="pa-5" flat>
                <h1>Terms, conditions &amp; privacy</h1><br>

        

                <h2>Music</h2>
                <h4>Usage &amp; Copyright</h4>
                <p>All music on this site remains under copyright by Kris Oliver, however it is made available to stream and download strickly for personal use.
                  If you wish to use/license a track for any other purposes a request must be made and granted in writing. 
                  Please email <a class="text-decoration-none" href="mailto:kris@krisoliver.com" title="Contact email">kris@krisoliver.com</a> for further details.
                </p>

                <h2>Images &amp; other media</h2>
                <h4>Copyright</h4>
                <p>In certain cases, images have been sourced to use for illustrative purposes (ie., for credits and work experience) and this site makes no attempt to claim any such copyright of the material.
                  If you are the copyright owner and would like to have the content removed, please use the contact section below.
                </p>

                <br>
                <h2>Cookies</h2>
                <p>This site uses cookies purely for <a class="text-decoration-none" href="https://support.google.com/analytics/answer/6004245" target="_blank" title="Google Analytics">Google Analytics</a> purposes.</p>

                <h4>What are Cookies?</h4>
                <p>Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. </p>

                <h4>How do I change my cookie settings?</h4>
                <p>Most web browsers allow some control of most cookies through the browser settings. 
                To find out more about cookies, including how to see what cookies have been set, visit 
                <a href="www.aboutcookies.org" target="_blank"  title="About Cookies" class="text-decoration-none">www.aboutcookies.org</a> or 
                <a href="www.allaboutcookies.org" target="_blank"  title="All About Cookies" class="text-decoration-none">www.allaboutcookies.org</a>.
                </p>

                <br><h2>Have more questions?</h2>
                <p>Contact Kris Oliver using the links in the footer below.</p>

          </v-card>
    </v-layout>
</template>

<script>

export default {
  name: 'Terms',
  data() {
      return { 
      }
  },
  methods: {

  },
}
</script>

<style scoped>
 
</style>