import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex);

import allTracks from '../datasets/tracks.json';

export default new Vuex.Store({
    state: {
        isLoading       : true,
        youtubePlayer   : false,
        youtubeVideo    : null,
        audioTrack      : null,
        sideBar         : null,
        sideBarActive   : false,
        playlist        : allTracks,
        availableGenres : [],
        playlistFilters : [],
        newFilter       : null,
    },
    getters: {
        isLoading       : (state) => state.isLoading,
        youtubePlayer   : (state) => state.youtubePlayer,
        youtubeVideo    : (state) => state.youtubeVideo,
        sideBar         : (state) => state.sideBar,
        sideBarActive   : (state) => state.sideBarActive,
        audioTrack      : (state) => state.audioTrack,
        playlist        : (state) => state.playlist,
        playlistFilters : (state) => state.playlistFilters,
        availableGenres(state){

            let foundkeywords   = [];
            let currentSearch   = state.playlistFilters;

            if(state.playlistFilters.length == 0){
                state.playlist = allTracks;
            }

            let filters     = [];
            for(var word = 0; word < currentSearch.length; word++){
                let eachword = currentSearch[word].label.split(/\s+/);
                filters      = filters.concat(eachword);
            }
            filters = filters.map(v => v.toLowerCase());

            for (var keyword = 0; keyword < state.playlist.length; keyword++) {
                foundkeywords = foundkeywords.concat(state.playlist[keyword].genres);
            }
            var frequency = {}, value;
            for(var i = 0; i < foundkeywords.length; i++) {
                value = foundkeywords[i];
                if(value in frequency) {    frequency[value]++; }
                else {                      frequency[value] = 1; }
            }
            
            var uniques = [];
            var regex = new RegExp(filters.join( "|" ), "i");
            
            for(value in frequency) {
                if(filters.length && regex.test(value)){
                    continue;
                } 

                uniques.push({
                    "label" :  value,
                    "count" :  frequency[value],            
                    }
                );
            }

            uniques.sort((a, b) => b['count'] - a['count']);
            return uniques;
        
       
        },
  
    },
    mutations: {
        setLoading      : (state, isLoading)        => (state.isLoading = isLoading),
        youtubePlayer   : (state, youtubePlayer)    => (state.youtubePlayer = youtubePlayer),
        youtubeVideo    : (state, youtubeVideo)     => (state.youtubeVideo = youtubeVideo),
        playlistFilters : (state, playlistFilters)  => (state.playlistFilters = playlistFilters),
        sideBarActive   : (state, sideBarActive)    => (state.sideBarActive = sideBarActive),
        sideBar         : (state, sideBar) => {
                                state.sideBar       = sideBar
                                state.sideBarActive = true        
                        },
        audioTrack : (state, audioTrack) => (state.audioTrack = audioTrack),
        updateFilters(state, item){
            let currentKeys = state.playlistFilters;
            let index   = currentKeys.indexOf(item);

            (currentKeys.includes(item))? currentKeys.splice(index, 1) : currentKeys.push(item);
            state.playlistFilters = currentKeys;


            let currentFilters = state.playlistFilters;
            if(currentFilters == 0){ 
                state.playlist = allTracks;
                return
            } 

            let filters     = [];
            for(var i = 0; i < currentFilters.length; i++){
                let eachword = currentFilters[i].label.split(/\s+/);
                filters      = filters.concat(eachword);
            }
            filters = filters.map(v => v.toLowerCase());

            state.playlist = allTracks.filter((item) =>{

                let tags = [];
                if(item.genres){
                    for(var k = 0; k < item.genres.length; k++){
                        let genre = item.genres[k].replace(/\W/g, ' ');
                        let eachword = genre.split(/\s+/);
                        tags = tags.concat(eachword);
                    }
                }     

                tags = tags.map(v => v.toLowerCase());
                let checker = (arr, target) => target.every(v => arr.includes(v));
                let isFound = (checker(tags, filters))? true : false;

                return (isFound)? item : false;        
            })




        }
    },
    actions: {
        setLoading(status){
            status.commit('setLoading')
        },
        toggleYoutubePlayer(){
            this.state.youtubePlayer = !this.state.youtubePlayer;
        }, 
  
    }
})