<template>
    <v-banner 
        v-model="optIn"
        two-line 
        class="grey lighten-2 body-2"
     >  
        <v-avatar
            slot="icon"
            color="deep-purple accent-4"
            size="40"
            
        >
            <v-icon
            icon="mdi-cookie"
            color="white"
            >
            mdi-cookie
            </v-icon>
        </v-avatar>

            This site uses Google Analytics cookies<br>
            For more information visit <a href="/terms">Terms &amp; Privacy</a> page

        <template v-slot:actions>
            <v-btn
                @click="disableTracking()"
                text
                color="deep-purple accent-4"
            >
            Refuse
            </v-btn>
            <v-btn
                @click="enableTracking()"
                text
                color="deep-purple accent-4"
            >
            Accept
            </v-btn>
        </template>
    </v-banner>
</template>

<script>
import Vue from 'vue'

export default {
    data() {
        return { 
            optIn   : false,
        }
    },
    mounted(){
        let isSet = Vue.$cookies.get('cookie-check');
        if(!isSet) this.optIn = true;
    },
    methods: {
        disableTracking() {
            this.$ga.disable();
            this.optIn = false;
            Vue.$cookies.set('cookie-check', 'refused', '9m');
        },
        enableTracking() {
            this.$ga.enable();
            this.optIn = false;
            Vue.$cookies.set('cookie-check', 'accepted', '9m');
        },
    }
}
</script>

<style>

</style>