<template>
    <v-layout align-content-center  justify-center wrap class="py-4" >
  
    
              <v-card
                  width="320"
                  height="180"
                  class="ma-2 justify-center"
                  color="primary"
              >
  
                <v-card-text class="white--text text-left pa-3">
                  <p style="font-size:16px;">Kristofer Oliver is a multidisciplinary creator specialising in delivering original content, technical/artistic consultation
                  and standalone commission-based projects. </p> 
                </v-card-text>
              </v-card>

              <v-card
                  width="320"
                  class="ma-2"
                  v-for="(item, index) in videos" 
                  :key="index"
                  @click="openCredit(item)"
              >   
                  <v-img    
                      height="180"
                      class="white--text align-end grey lighten-1"
                      :src="(item.youtube)? 'https://img.youtube.com/vi/'+item.youtube+'/hqdefault.jpg' : getSrc(item.img)"
                  >   
                      <v-app-bar
                        flat dense
                        color="rgba(0, 0, 0, 0.3)"
                      >
                        <v-toolbar-title class="subtitle-1 white--text pl-0">
                          {{ item.title }}
                        </v-toolbar-title>
                      </v-app-bar>
                  </v-img>              
              </v-card>
    </v-layout>
</template>

<script>
import allCredits from '../datasets/credits.json';

export default {
  name: 'Watch',
  data() {
      return { 
          credits   : allCredits,
      }
  },
  computed: {
    videos(){
        return this.credits.filter((item) =>{
          return (item.type == "video")? item : false;            
        })
    }
  },
  methods: {
        openCredit(credit){
            this.$store.commit('sideBar', credit);
        },
        getSrc(img) {
            if(!img) return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
            try {
                return require('../assets/'+img) 
            }
            catch (e) {
                console.log('Failed to load ../assets/'+img)
                return 'https://cdn.vuetifyjs.com/images/cards/docks.jpg';
            }
        },
  },
}
</script>

<style scoped>
    .v-card__title {
        display: block;
        position: relative;
        padding: 10px 15px;
        margin: 0;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0rem;
        word-break: normal;
    }
    .v-card__text{
        font-size: 15px;
    }
 
</style>