<template>
      <v-layout align-content-center justify-center wrap class="px-5 my-5">
          <v-card max-width="950" class="pa-5" flat>
                <h1>404 -ops!</h1>
                <p>This is not the page you were looking for!</p>
          </v-card>
      </v-layout>    
</template>

<script>
export default {

}
</script>

<style>

</style>